import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindBlogQueryVariables = Types.Exact<{
  slug: Types.Scalars['ID']['input'];
}>;


export type FindBlogQuery = { __typename?: 'Query', blog?: { __typename?: 'Blog', _id: string, title: string, content: string, slug: string, published: boolean, created_at: string, updated_at: string } | null };


export const FindBlogDocument = gql`
    query findBlog($slug: ID!) {
  blog(slug: $slug) {
    _id
    title
    content
    slug
    published
    created_at
    updated_at
  }
}
    `;

/**
 * __useFindBlogQuery__
 *
 * To run a query within a React component, call `useFindBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBlogQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFindBlogQuery(baseOptions: Apollo.QueryHookOptions<FindBlogQuery, FindBlogQueryVariables> & ({ variables: FindBlogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindBlogQuery, FindBlogQueryVariables>(FindBlogDocument, options);
      }
export function useFindBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindBlogQuery, FindBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindBlogQuery, FindBlogQueryVariables>(FindBlogDocument, options);
        }
export function useFindBlogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindBlogQuery, FindBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindBlogQuery, FindBlogQueryVariables>(FindBlogDocument, options);
        }
export type FindBlogQueryHookResult = ReturnType<typeof useFindBlogQuery>;
export type FindBlogLazyQueryHookResult = ReturnType<typeof useFindBlogLazyQuery>;
export type FindBlogSuspenseQueryHookResult = ReturnType<typeof useFindBlogSuspenseQuery>;
export type FindBlogQueryResult = Apollo.QueryResult<FindBlogQuery, FindBlogQueryVariables>;