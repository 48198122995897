import { localeEN, localeNL } from '@sal-solution/utils';

export const footerLocales = {
  [localeEN]: {
    about: 'About',
    contact: 'Contact',
    privacy: 'Privacy',
    terms: 'Terms',
    disclaimer: 'Disclaimer',
    cookies: 'Cookies',
    sitemap: 'Sitemap',
    company: 'Company',
    legal: 'Legal',
  },
  [localeNL]: {
    about: 'Over',
    contact: 'Contact',
    privacy: 'Privacy',
    terms: 'Voorwaarden',
    disclaimer: 'Disclaimer',
    cookies: 'Cookies',
    sitemap: 'Sitemap',
    company: 'Bedrijf',
    legal: 'Juridisch',
  },
};
