'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Section, Text } from '@sal-solution/ui-web';
import ReactMarkdown from 'markdown-to-jsx';
import { useParams } from 'next/navigation';
import { useFindBlogQuery } from './graphql/findBlog.generated';
import './styles.css';

export const Blog = () => {
  const params = useParams();
  const { data, loading, error } = useFindBlogQuery({
    variables: {
      slug: params.slug as string,
    },
  });

  const date = new Date(Number(data?.blog?.created_at));
  const daysAgo = Math.floor(
    (Date.now() - date.getTime()) / (1000 * 60 * 60 * 24)
  );
  const isNew = daysAgo < 2;

  return (
    <Section tw="flex flex-col items-start justify-start px-4 space-y-4">
      <div tw="flex flex-col">
        {loading && <Text>Loading...</Text>}
        {error && <Text>Error: {error.message}</Text>}
        <Text variant="title">
          {data?.blog?.title}{' '}
          <span tw="font-normal text-sm">
            ({isNew ? 'Nieuw' : `${daysAgo} dagen geleden`})
          </span>
        </Text>
        <Text tw="italic">/{data?.blog?.slug}</Text>
      </div>
      <div className="markdown">
        <ReactMarkdown>{data?.blog?.content as string}</ReactMarkdown>
      </div>
    </Section>
  );
};
