'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Section, SectionDivider } from '@sal-solution/ui-web';
import { FC } from 'react';

import { ContactForm } from '../../components';
import { contactPageLocale } from '../../locale';
import { useLocale } from '../../hooks';

export const Contact: FC = () => {
  const locale = useLocale(contactPageLocale);

  return (
    <>
      <SectionDivider title={locale.title} id="contact" />
      <Section tw="px-8 pt-0">
        <ContactForm />
      </Section>
    </>
  );
};
