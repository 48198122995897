'use client';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { BlogCard, HeroSection, Text } from '@sal-solution/ui-web';
import Link from 'next/link';
import tw from 'twin.macro';
import { useFindBlogsQuery } from './graphql/findBlogs.generated';

export const Blogs = () => {
  const { data, loading, error } = useFindBlogsQuery({
    variables: {
      published: true,
    },
  });
  return (
    <HeroSection
      bottomVariant="Waves"
      bottomFill="rgb(250 245 255)"
      innerStyle={[tw`flex flex-col items-start justify-start px-4`]}
    >
      <div tw="col-span-4">
        {loading && <Text>Loading...</Text>}
        {error && <Text>Error: {error.message}</Text>}
        <Text variant="title">Artikelen</Text>
      </div>
      <div tw="grid gap-4 lg:(grid-cols-2)">
        {data?.blogs?.map((blog) => (
          <Link href={`/blog/${blog?.slug}`} key={blog?._id as string}>
            <BlogCard
              title={blog?.title as string}
              content={blog?.content as string}
              createdAt={parseInt(blog?.created_at as string)}
            />
          </Link>
        ))}
      </div>
    </HeroSection>
  );
};
