'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';

import { ChatBubbleLeftIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Button, Rating, Section, Table, Text } from '@sal-solution/ui-web';
import { useSearchParams } from 'next/navigation';
import { FC } from 'react';
import { useFindOfferRepliesQuery } from './graphql/findOfferReplies.generated';
import Link from 'next/link';
import { useAcceptOfferMutation } from './graphql/acceptOffer.generated';
import { timestampToAgo } from '@sal-solution/utils';

export const OfferReplies: FC = () => {
  const params = useSearchParams();
  const offerId = params.get('offerId');

  const { data, loading, error, refetch } = useFindOfferRepliesQuery({
    variables: {
      offerId: offerId,
    },
    skip: !offerId,
  });

  const [acceptOffer, response] = useAcceptOfferMutation();

  const onAcceptOffer = async (responseId?: string | null) => {
    if (!responseId) return;

    const confirm = window.confirm(
      'Weet je zeker dat je dit bod wilt accepteren?'
    );

    if (!confirm) return;

    await acceptOffer({
      variables: {
        offerId,
        responseId,
      },
    });
    await refetch();
  };

  const isLoading = loading || response.loading;
  const isError = error || response.error;

  const offer = data?.findOfferReplies?.offer;
  const contractors = data?.findOfferReplies?.contractors;
  console.log('contractors', contractors);
  return (
    <Section tw="flex-col justify-start items-start space-y-4 px-4">
      {isLoading && <Text>Loading...</Text>}
      {isError && <Text>{isError.message}</Text>}
      <div>
        <Text variant="title">Uw offerte aanvraag:</Text>
        {offer?.kitchenSize && offer?.kitchenSize === 'unknown' && (
          <Text>{offer?.kitchenSize}</Text>
        )}
        {offer?.kitchenColor && offer?.kitchenColor === 'unknown' && (
          <Text>
            {offer?.kitchenColor === 'unknown'
              ? 'Geen kleur gekozen'
              : offer?.kitchenColor}
          </Text>
        )}
        {offer?.kitchenDate && offer?.kitchenDate !== 'unknown' && (
          <Text>{offer?.kitchenDate}</Text>
        )}
        {offer?.wrapTargets && offer?.wrapTargets.length > 0 && (
          <Text>{offer?.wrapTargets.join(', ')}</Text>
        )}
        {offer?.created_at && (
          <Text>Aangemaakt: {timestampToAgo(offer?.created_at)}</Text>
        )}
      </div>

      <div>
        <Text variant="title">Reacties:</Text>
        <Text tw="italic text-sm">
          Alle reacties zijn een schatting en kunnen mogelijk veranderen.
          Bespreek dit goed door met de aannemer
        </Text>
      </div>
      <div tw="grid grid-cols-2 items-center lg:(grid-cols-3) w-full">
        <Text tw="text-xs text-gray-500">Bod</Text>
        <Text tw="text-xs text-gray-500">Beoordeling</Text>
        <Text tw="hidden lg:(block col-span-1)"></Text>
        {data?.findOfferReplies?.contractors?.map((contractor, i) => (
          <>
            <div tw="divider col-span-2 mt-0 opacity-80 lg:(col-span-3)"></div>
            <Text key={i}>
              {contractor?.name || 'Onbekend'} - €{contractor?.amount}{' '}
              {contractor?.accepted ? '(Geïnteresseerd)' : ''}
            </Text>
            <Rating key={i} rating={contractor?.rating || 5} />

            <div key={i} tw="flex gap-4 col-span-2 mt-2 lg:(col-span-1 mt-0)">
              <Button
                tw=""
                variant="success"
                disabled={response.loading}
                onClick={() => onAcceptOffer(contractor?.responseId)}
              >
                Interesse
              </Button>
              <Button tw="relative">
                {contractor?.commentsCount ? (
                  <div
                    tw="badge badge-accent absolute -top-2 -right-4"
                    className="badge badge-primary"
                  >
                    {contractor?.commentsCount}
                  </div>
                ) : (
                  <></>
                )}
                <Link
                  href={`/offerte-comments?offerId=${offerId}&responseId=${contractor?.responseId}&source=me`}
                >
                  <ChatBubbleLeftIcon tw="w-4 h-4" />
                </Link>
              </Button>
            </div>
          </>
        )) || []}
      </div>
    </Section>
  );
};
