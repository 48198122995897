import { localeEN, localeNL } from '@sal-solution/utils';

export const formLocales = {
  [localeEN]: {
    title: 'Upload User Information',
    description: 'Please upload your user information',
    uploadButton: 'Upload',
    uploadSuccess: 'Upload success',
    uploadFailed: 'Upload failed',
  },
  [localeNL]: {
    title: 'Gebruikersinformatie uploaden',
    description: 'Upload uw gebruikersinformatie',
    uploadButton: 'Upload',
    uploadSuccess: 'Uploaden gelukt',
    uploadFailed: 'Uploaden mislukt',
  },
};
