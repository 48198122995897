import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindOfferKitchenContractorSsrQueryVariables = Types.Exact<{
  findOfferKitchenContractorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type FindOfferKitchenContractorSsrQuery = { __typename?: 'Query', findOfferKitchenContractor?: { __typename?: 'OfferKitchenContractor', _id: string, town: string, wrapTargets?: Array<string | null> | null, kitchenSize: string, kitchenColor: string, kitchenDate: string, created_at: string } | null };


export const FindOfferKitchenContractorSsrDocument = gql`
    query findOfferKitchenContractorSSR($findOfferKitchenContractorId: ID) {
  findOfferKitchenContractor(id: $findOfferKitchenContractorId) {
    _id
    town
    wrapTargets
    kitchenSize
    kitchenColor
    kitchenDate
    created_at
  }
}
    `;

/**
 * __useFindOfferKitchenContractorSsrQuery__
 *
 * To run a query within a React component, call `useFindOfferKitchenContractorSsrQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOfferKitchenContractorSsrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOfferKitchenContractorSsrQuery({
 *   variables: {
 *      findOfferKitchenContractorId: // value for 'findOfferKitchenContractorId'
 *   },
 * });
 */
export function useFindOfferKitchenContractorSsrQuery(baseOptions?: Apollo.QueryHookOptions<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>(FindOfferKitchenContractorSsrDocument, options);
      }
export function useFindOfferKitchenContractorSsrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>(FindOfferKitchenContractorSsrDocument, options);
        }
export function useFindOfferKitchenContractorSsrSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>(FindOfferKitchenContractorSsrDocument, options);
        }
export type FindOfferKitchenContractorSsrQueryHookResult = ReturnType<typeof useFindOfferKitchenContractorSsrQuery>;
export type FindOfferKitchenContractorSsrLazyQueryHookResult = ReturnType<typeof useFindOfferKitchenContractorSsrLazyQuery>;
export type FindOfferKitchenContractorSsrSuspenseQueryHookResult = ReturnType<typeof useFindOfferKitchenContractorSsrSuspenseQuery>;
export type FindOfferKitchenContractorSsrQueryResult = Apollo.QueryResult<FindOfferKitchenContractorSsrQuery, FindOfferKitchenContractorSsrQueryVariables>;