import { SmartForm, SmartFormElement } from '@sal-solution/ui-web';
import { localeEN, localeNL } from '@sal-solution/utils';
import React, { FC } from 'react';
import { useContactMutation } from './contact.generated';
import { toast } from 'react-toastify';
import { useLocale } from '../../hooks';

export const ContactForm: FC = () => {
  const locale = useLocale(locales);
  const [sendForm, { data, loading, error }] = useContactMutation();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    console.log('form press');

    const email = form.get('email') as string;
    const message = form.get('message') as string;
    const name = form.get('name') as string;
    const phone = form.get('phone') as string;

    if (!email || !message || !name) {
      toast.error('Please fill in all required fields');
      return;
    }

    const response = await sendForm({
      variables: {
        input: {
          email: email,
          message: message,
          name: name,
          phone: phone,
          subject: 'Contact request',
        },
      },
    });

    if (response.errors) {
      console.error(response.errors);
      toast.error('Failed to send contact request');
    }
    if (response.data) {
      toast.success('Contact request sent successfully');
    }
    console.log('form end');
  };
  return (
    <SmartForm
      elements={[locale]}
      onSubmit={onSubmit}
      loading={loading}
      submitText="Verstuur"
    />
  );
};

export const fieldsNL: SmartFormElement[] = [
  {
    name: 'name',
    label: 'Naam',
    placeholder: 'John Doe',
    required: true,
    type: 'input',
  },
  {
    name: 'email',
    label: 'Email',
    placeholder: 'voorbeeld@email.com',
    required: true,
    type: 'input',
  },
  {
    name: 'phone',
    label: 'Telefoonnummer',
    placeholder: '0612345678',
    type: 'input',
  },
  {
    name: 'message',
    label: 'Bericht',
    placeholder: 'Uw bericht hier...',
    required: true,
    type: 'textArea',
  },
  {
    label: 'Algemene voorwaarden',
    type: 'checkmark',
    required: true,
    checkMarks: [
      {
        name: 'terms',
        label: 'Ik ga akkoord met de algemene voorwaarden',
      },
    ],
  },
];

export const fieldsEN: SmartFormElement[] = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'John Doe',
    required: true,
    type: 'input',
  },
  {
    name: 'email',
    label: 'Email',
    placeholder: 'example@email.com',
    required: true,
    type: 'input',
  },
  {
    name: 'phone',
    label: 'Phone number',
    placeholder: '0612345678',
    type: 'input',
  },
  {
    name: 'message',
    label: 'Message',
    placeholder: 'Your message here...',
    required: true,
    type: 'textArea',
  },
  {
    label: 'Terms and conditions',
    type: 'checkmark',
    required: true,
    checkMarks: [
      {
        name: 'terms',
        label: 'I agree to the terms and conditions',
      },
    ],
  },
];

const locales = {
  [localeEN]: fieldsEN,
  [localeNL]: fieldsNL,
};
