'use client';
import { ApolloAppProvider } from '@/context/ApolloClient';
import { useCurrentLocale } from '@/hooks/useLocal';
import { GlobalStyles } from '@/styles/GlobalStyles';
import { validateLocale } from '@/utils/locale';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import { CookieConsentBanner, Futty } from '@sal-solution/ui-web';
import { usePathname } from 'next/navigation';
import NextTopLoader from 'nextjs-toploader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './global.css';
import RootStyleRegistry from './emotion';
import { FacebookPixel } from '@/components';
import { Suspense } from 'react';
import { FacebookPixelEvents } from '@/components/FacebookPixel/FacebookPixelEvents';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const localeInPath = validateLocale(pathname.split('/')[1]);
  const locale = useCurrentLocale();
  return (
    <html lang={localeInPath} data-theme="light">
      <head>
        <GoogleTagManager gtmId="GTM-WVX9T47L" />
        <GoogleAnalytics gaId="G-LCCWTXQ4BE" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        };
        gtag('js', new Date());
        `,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `gtag('config', 'AW-16583139963');`,
          }}
        ></script>
      </head>
      <body>
        <RootStyleRegistry>
          <GlobalStyles />
          {/* <!-- Google Tag Manager (noscript) --> */}
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-WVX9T47L"
              height="0"
              width="0"
              style={{
                display: 'none',
                visibility: 'hidden',
              }}
            ></iframe>
          </noscript>
          {/* <!-- End Google Tag Manager (noscript) --> */}
          <NextTopLoader />
          <ApolloAppProvider>{children}</ApolloAppProvider>
          <ToastContainer />
          <Futty futtyKey="665cbe0ab5496" />
          <CookieConsentBanner locale={locale} />
          {/* <Script
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="d7f94b02-50c6-40d8-a1d5-a14eccd2dcc6"
            data-blockingmode="auto"
            type="text/javascript"
          /> */}
        </RootStyleRegistry>
        <Suspense fallback={null}>
          <FacebookPixelEvents />
        </Suspense>
      </body>
    </html>
  );
}
