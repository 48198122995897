'use client';
import { Button, Form, Input, Section, Text } from '@sal-solution/ui-web';
import { useSearchParams } from 'next/navigation';
/** @jsxImportSource @emotion/react */

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import 'twin.macro';
import { useFindOfferKitchenContractorSsrQuery } from './graphql/findOfferKitchenContractor.generated';
import { useReplyToOfferRequestMutation } from './graphql/replyToOfferRequest.generated';

export const OfferReply = () => {
  const params = useSearchParams();
  const offerId = params.get('offerId');
  const [reply, { data, loading, error }] = useReplyToOfferRequestMutation();
  const offerResponse = useFindOfferKitchenContractorSsrQuery({
    variables: {
      findOfferKitchenContractorId: offerId,
    },
    skip: !offerId,
  });
  const to = params.get('to');
  // const color = params.get('color');
  if (!offerId || !to) {
    return (
      <Section>
        <Text>Invalid offer reply</Text>
      </Section>
    );
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    console.log('' + form.get('offer'));
    await reply({
      variables: {
        input: {
          offerId: offerId,
          contractorId: to,
          amount: '' + form.get('offer'),
        },
      },
    });
  };

  // const exDate = new Date(expirationDate || 0);

  if (data?.replyToOfferRequest) {
    return (
      <Section tw="flex-col justify-center space-y-4 px-4">
        {error && <Text tw="text-red-500">{error.message}</Text>}
        <CheckCircleIcon tw="w-16 h-16 mr-4 text-green-500" />

        <Text tw="text-green-500" variant="subTitle">
          Voorstel is verstuurd
        </Text>
        <Button
          disabled={!data?.replyToOfferRequest}
          variant="light"
          as={Link}
          href={`/offerte-comments?offerId=${offerId}&contractorId=${to}&responseId=${data?.replyToOfferRequest}&source=contractor`}
        >
          Chat met de aanvrager
        </Button>
      </Section>
    );
  }

  const offer = offerResponse.data?.findOfferKitchenContractor;
  const hasError = error || offerResponse.error;
  const isLoading = offerResponse.loading || loading;

  return (
    <Section tw="flex-col justify-start space-y-4 px-4">
      {error && <Text tw="text-red-500">{hasError?.message}</Text>}
      {isLoading && <Text>Loading...</Text>}
      {data?.replyToOfferRequest && (
        <Text tw="text-green-500" variant="subTitle">
          Voorstel is verstuurd
        </Text>
      )}
      <Text tw="w-full" variant="title">
        Offerte aanvraag
      </Text>
      <Text tw="w-full">
        Plaats hieronder je prijsindicatie. Wij informeren de klant over je bod
        en nemen contact op bij akkoord.
      </Text>
      <div tw="px-4 py-4 bg-slate-100 rounded-md w-full">
        {offer?.town && (
          <Text>
            <span tw="font-bold">Locatie:</span> {offer?.town}
          </Text>
        )}
        {offer?.wrapTargets && offer?.wrapTargets.length > 0 && (
          <Text>
            <span tw="font-bold">Wilt laten wrappen:</span>{' '}
            {offer?.wrapTargets?.join(',')}
          </Text>
        )}
      </div>
      <Form tw="mr-auto" onSubmit={onSubmit}>
        <Input label="Schatting offerte" name="offer" required />
        <Text tw="text-xs italic">
          Na akkoord van de klant ontvang je automatisch alle contactgegevens.
          Het delen/ontvangen van opmerkingen kan ondertussen ook via de
          chatfunctie.
        </Text>

        <div tw="flex gap-4 w-full mt-4">
          <Button
            disabled={!data?.replyToOfferRequest}
            variant="light"
            as={Link}
            href={`/offerte-comments?offerId=${offerId}&contractorId=${to}&responseId=${data?.replyToOfferRequest}&source=contractor`}
          >
            Chat met de aanvrager
          </Button>
          <Button disabled={loading}>
            {loading ? 'Loading' : 'Plaats een bod'}
          </Button>
        </div>
      </Form>
    </Section>
  );
};
