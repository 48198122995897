/** @jsxImportSource @emotion/react */

'use client';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { getCookie } from '../utils/cookies';
import { FC, PropsWithChildren } from 'react';

const createApolloClient = (headers = null) => {
  const uri = process.env.GRAPHQL_URL || 'http://localhost:4000/graphql';

  const httpLink = createHttpLink({
    uri: uri,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.GRAPHQL_URL_WS as string,
    })
  );

  const authLink = setContext((_, { headers, ...rest }) => {
    // get the authentication token from local storage if it exists
    const foundSession = getCookie('token') || undefined;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: foundSession ? `Bearer ${foundSession}` : '',
      },
    };
  });

  const createApolloLink = (link: ApolloLink) => {
    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    //  return authLink.concat(link);
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(link)
    );
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const createdApolloLink = createApolloLink(errorLink.concat(httpLink));

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createdApolloLink,
    cache: new InMemoryCache(),
  });
  return client;
};

export const initializeApollo = (initialState = null, headers = null) => {
  const _apolloClient = createApolloClient(headers);

  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }

  return _apolloClient;
};

const client = createApolloClient();

export const ApolloAppProvider: FC<PropsWithChildren> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
