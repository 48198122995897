import { localeNL, locales } from '@sal-solution/utils';
import { usePathname } from 'next/navigation';

export function useCurrentLocale() {
  const pathname = usePathname();
  return locales.find((locale) => pathname.includes(locale));
}

export function useLocale<T extends object>(pageText: T) {
  const locale = useCurrentLocale();
  if (!locale) {
    return pageText[localeNL as keyof T];
  }

  return pageText[locale as keyof T];
}
