import { localeEN, localeNL } from '@sal-solution/utils';

export const navigationLocale = {
  [localeEN]: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    login: 'Login',
    register: 'Register',
    blog: 'Blog',
    language: 'Language',
  },
  [localeNL]: {
    home: 'Home',
    about: 'Over',
    contact: 'Contact',
    login: 'Inloggen',
    register: 'Registreren',
    blog: 'Blog',
    taal: 'Taal',
  },
};
