import { localeEN, localeNL } from '@sal-solution/utils';

export const contactPageLocale = {
  [localeEN]: {
    title: 'Any questions?',
    subTitle: 'Contact us',
  },
  [localeNL]: {
    title: 'Vragen?',
    subTitle: 'Neem contact met ons op',
  },
};
