import { localeEN, localeNL } from '@sal-solution/utils';

export const actionLocales = {
  [localeEN]: {
    send: 'Send',
    cancel: 'Cancel',
    close: 'Close',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    confirm: 'Confirm',
    back: 'Back',
    next: 'Next',
    previous: 'Previous',
    submit: 'Submit',
    reset: 'Reset',
    clear: 'Clear',
    search: 'Search',
    filter: 'Filter',
    select: 'Select',
    deselect: 'Deselect',
    view: 'View',
    download: 'Download',
    upload: 'Upload',
    copy: 'Copy',
    paste: 'Paste',
    cut: 'Cut',
    undo: 'Undo',
    redo: 'Redo',
    refresh: 'Refresh',
    print: 'Print',
    preview: 'Preview',
    expand: 'Expand',
    collapse: 'Collapse',
    uploadImage: 'Upload image',
    uploadImages: 'Upload images',
    requestOffer: 'Request offer',
    requestFreeOffer: 'Request free offer',
  },
  [localeNL]: {
    send: 'Verzenden',
    cancel: 'Annuleren',
    close: 'Sluiten',
    save: 'Opslaan',
    edit: 'Bewerken',
    delete: 'Verwijderen',
    add: 'Toevoegen',
    remove: 'Verwijderen',
    confirm: 'Bevestigen',
    back: 'Terug',
    next: 'Volgende',
    previous: 'Vorige',
    submit: 'Verzenden',
    reset: 'Resetten',
    clear: 'Leegmaken',
    search: 'Zoeken',
    filter: 'Filteren',
    select: 'Selecteren',
    deselect: 'Deselecteren',
    view: 'Bekijken',
    download: 'Downloaden',
    upload: 'Uploaden',
    copy: 'Kopiëren',
    paste: 'Plakken',
    cut: 'Knippen',
    undo: 'Ongedaan maken',
    redo: 'Opnieuw',
    refresh: 'Verversen',
    print: 'Printen',
    preview: 'Voorbeeld',
    expand: 'Uitklappen',
    collapse: 'Inklappen',
    uploadImage: 'Afbeelding uploaden',
    uploadImages: 'Afbeeldingen uploaden',
    requestOffer: 'Offerte aanvragen',
    requestFreeOffer: 'Gratis offerte aanvragen',
  },
};
