import * as Types from '@sal-solution/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindBlogsQueryVariables = Types.Exact<{
  published?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type FindBlogsQuery = { __typename?: 'Query', blogs?: Array<{ __typename?: 'Blog', _id: string, title: string, content: string, slug: string, published: boolean, created_at: string, updated_at: string } | null> | null };


export const FindBlogsDocument = gql`
    query findBlogs($published: Boolean) {
  blogs(published: $published) {
    _id
    title
    content
    slug
    published
    created_at
    updated_at
  }
}
    `;

/**
 * __useFindBlogsQuery__
 *
 * To run a query within a React component, call `useFindBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBlogsQuery({
 *   variables: {
 *      published: // value for 'published'
 *   },
 * });
 */
export function useFindBlogsQuery(baseOptions?: Apollo.QueryHookOptions<FindBlogsQuery, FindBlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindBlogsQuery, FindBlogsQueryVariables>(FindBlogsDocument, options);
      }
export function useFindBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindBlogsQuery, FindBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindBlogsQuery, FindBlogsQueryVariables>(FindBlogsDocument, options);
        }
export function useFindBlogsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindBlogsQuery, FindBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindBlogsQuery, FindBlogsQueryVariables>(FindBlogsDocument, options);
        }
export type FindBlogsQueryHookResult = ReturnType<typeof useFindBlogsQuery>;
export type FindBlogsLazyQueryHookResult = ReturnType<typeof useFindBlogsLazyQuery>;
export type FindBlogsSuspenseQueryHookResult = ReturnType<typeof useFindBlogsSuspenseQuery>;
export type FindBlogsQueryResult = Apollo.QueryResult<FindBlogsQuery, FindBlogsQueryVariables>;