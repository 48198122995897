'use client';
/** @jsxImportSource @emotion/react */

import 'twin.macro';
import * as Icon from '@heroicons/react/24/outline';
import { Section, Text } from '@sal-solution/ui-web';
import { FC } from 'react';

export const Confirmation: FC = () => {
  return (
    <>
      <Section tw="px-8 flex-col items-center justify-center">
        <Icon.CheckCircleIcon tw="w-24 h-24 text-green-500 " />
        <Text tw="text-center" variant="title">
          Uw aanvraag is ontvangen!
        </Text>
        <Text tw="text-center">
          Wij hebben uw aanvraag ontvangen en zullen deze zo snel mogelijk
          verwerken.
        </Text>
      </Section>
      <script
        dangerouslySetInnerHTML={{
          __html: `
         gtag('event', 'conversion', {
          'send_to': 'AW-16583139963/XvGUCJeRiLkZEPvEuuM9'
         });
         `,
        }}
      ></script>
    </>
  );
};
